import { Suspense, lazy, useEffect, useState } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import { PATH_AFTER_LOGIN, PATH_AFTER_ADMIN_LOGIN } from '../config';
import LoadingScreen from '../components/LoadingScreen';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import AdminDashboardLayout from '../layouts/adminDashboard';
import MainLayout from '../layouts/main';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import axiosInstance from '../utils/axios';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await axiosInstance.get('user/all/dropdown/view');
        console.log(response, 'response')
        setPages(response?.data?.data);
      } catch (error) {
        console.error('Error fetching pages:', error);
      }
    };

    fetchPages();
  }, []);

  const dynamicRoutes = pages.map((page) => ({
    path: `pages/${page.id}`,
    element: <Resources title={page.resource_name} id={page?.id}/>,
  }));


  return useRoutes([
    // User Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'forgotVerify/:token', element: <ForgotVerify /> },
        { path: 'newPassword', element: <NewPasswordForm /> },
      ],
    },

    // User Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'analytics', element: <GeneralAnalytics /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'pdf',
          children: [
            { element: <Navigate to="/dashboard/pdf" replace />, index: true },
            { path: 'pdf', element: <Pdf /> },
          ],
        },
        {
          path: 'cv',
          children: [
            { element: <Navigate to="/dashboard/cv" replace />, index: true },
            { path: 'cv', element: <Cv /> },
          ],
        },
        {
          path: 'video',
          children: [
            { element: <Navigate to="/dashboard/video" replace />, index: true },
            { path: 'video', element: <Video /> },
            { path: 'editvideo/:id', element: <EditVideo /> },
            { path: 'addvideo', element: <AddVideo /> },
          ],
        },
        {
          path: 'numerical',
          children: [
            { element: <Navigate to="/dashboard/numerical" replace />, index: true },
            { path: 'numerical', element: <Numerical /> },
            { path: 'editnumerical/:id', element: <EditNumerical /> },
            { path: 'addnumerical', element: <AddNumerical /> },
          ],
        },
        {
          path: 'verbal',
          children: [
            { element: <Navigate to="/dashboard/verbal" replace />, index: true },
            { path: 'verbal', element: <Verbal /> },
            { path: 'editverbal/:id', element: <EditVerbal /> },
            { path: 'addverbal', element: <AddVerbal /> },
          ],
        },
        {
          path: 'logical',
          children: [
            { element: <Navigate to="/dashboard/logical" replace />, index: true },
            { path: 'logical', element: <Logical /> },
            { path: 'editlogical/:id', element: <EditLogical/> },
            { path: 'addlogical', element: <AddLogical /> },
          ],
        },
        {
          path: 'situational',
          children: [
            { element: <Navigate to="/dashboard/situational" replace />, index: true },
            { path: 'situational', element: <Situational /> },
            { path: 'editsituational/:id', element: <EditSituational/> },
            { path: 'addsituational', element: <AddSituational /> },
          ],
        },
        {
          path: 'quiz',
          children: [
            { element: <Navigate to="/dashboard/quiz/result" replace />, index: true },
            { path: 'result', element: <QuizResult /> },
          ],
        },
      ],
    },

    // Admin Auth
    {
      path: 'admin',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard roleType="admin">
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },

    // Admin Dashboard Routes
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <AdminDashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_ADMIN_LOGIN} replace />, index: true },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'account', element: <AdminAccount /> },
        {
          path: 'subscription',
          children: [
            { element: <Navigate to="/admin/subscription" replace />, index: true },
            { path: 'subscription', element: <Subcription /> },
            { path: 'editsubscription/:id', element: <EditSubcription /> },
            { path: 'addsubscription', element: <AddSubcription /> },
          ],
        },
        {
          path: 'addons',
          children: [
            { element: <Navigate to="/admin/addons" replace />, index: true },
            { path: 'addons', element: <Addons /> },
            { path: 'editaddons/:id', element: <EditAddons /> },
            { path: 'addaddons', element: <AddAddons /> },
          ],
        },
        {
          path: 'faq',
          children: [
            { element: <Navigate to="/admin/faq" replace />, index: true },
            { path: 'faq', element: <Faq /> },
            { path: 'editfaq/:id', element: <EditFaq /> },
            { path: 'addfaq', element: <AddFaq /> },
          ],
        },
        {
          path: 'pages',
          children: [
            { element: <Navigate to="/admin/pages" replace />, index: true },
            { path: 'pages', element: <Pages /> },
            { path: 'editpages/:id', element: <EditPages /> },
            { path: 'addpages', element: <AddPages /> },
            { path: 'content/:id', element: <Content /> },
            { path: 'editcontent/:id', element: <EditContent /> },
            { path: 'addcontent/:id', element: <AddContent /> },
          ],
        },
        {
          path: 'pdf',
          children: [
            { element: <Navigate to="/dashboard/pdf" replace />, index: true },
            { path: 'pdf', element: <AdminPdf /> },
            { path: 'addpdf', element: <AdminAddPdf /> },
            { path: 'editpdf/:id', element: <AdminEditPdf /> },
          ],
        },
        {
          path: 'cv',
          children: [
            { element: <Navigate to="/dashboard/cv" replace />, index: true },
            { path: 'cv', element: <AdminCv /> },
            { path: 'addcv', element: <AdminAddCv /> },
            { path: 'editcv/:id', element: <AdminEditCv /> },
          ],
        },
        {
          path: 'video',
          children: [
            { element: <Navigate to="/dashboard/video" replace />, index: true },
            { path: 'video', element: <AdminVideo /> },
            { path: 'editvideo/:id', element: <AdminEditVideo /> },
            { path: 'addvideo', element: <AdminAddVideo /> },
          ],
        },
        {
          path: 'numerical',
          children: [
            { element: <Navigate to="/dashboard/numerical" replace />, index: true },
            { path: 'numerical', element: <AdminNumerical /> },
            { path: 'editnumerical/:id', element: <AdminEditNumerical /> },
            { path: 'addnumerical', element: <AdminAddNumerical /> },
            { path: 'question/:id', element: <NumericalQuestion /> },
            { path: 'editquestion/:id', element: <EditNumericalQuestion /> },
            { path: 'addquestion/:id', element: <AddNumericalQuestion /> },
          ],
        },
        {
          path: 'logical',
          children: [
            { element: <Navigate to="/dashboard/logical" replace />, index: true },
            { path: 'logical', element: <AdminLogical /> },
            { path: 'editlogical/:id', element: <AdminEditLogical /> },
            { path: 'addlogical', element: <AdminAddLogical /> },
            { path: 'question/:id', element: <LogicalQuestion /> },
            { path: 'editquestion/:id', element: <EditLogicalQuestion /> },
            { path: 'addquestion/:id', element: <AddLogicalQuestion /> },
          ],
        },
        {
          path: 'verbal',
          children: [
            { element: <Navigate to="/dashboard/verbal" replace />, index: true },
            { path: 'verbal', element: <AdminVerbal /> },
            { path: 'editverbal/:id', element: <AdminEditVerbal /> },
            { path: 'addverbal', element: <AdminAddVerbal /> },
            { path: 'question/:id', element: <VerbalQuestion /> },
            { path: 'editquestion/:id', element: <EditVerbalQuestion /> },
            { path: 'addquestion/:id', element: <AddVerbalQuestion /> },
          ],
        },
        {
          path: 'situational',
          children: [
            { element: <Navigate to="/dashboard/situational" replace />, index: true },
            { path: 'situational', element: <AdminSituational /> },
            { path: 'editsituational/:id', element: <AdminEditSituational /> },
            { path: 'addsituational', element: <AdminAddSituational /> },
            { path: 'question/:id', element: <SituationalQuestion /> },
            { path: 'editquestion/:id', element: <EditSituationalQuestion /> },
            { path: 'addquestion/:id', element: <AddSituationalQuestion /> },
          ],
        },
      ],
    },
    // Admin Dashboard Routes

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },

        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'packagedetail/:id', element: <PackageDetail /> },
        { path: 'other-services', element: <ServicesOtherServices /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        ...dynamicRoutes,
        // { path: 'terms-of-agreement', element: <TermsOfAgreement /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const ForgotVerify = Loadable(lazy(() => import('../pages/auth/ForgotVerify')));
const NewPasswordForm = Loadable(lazy(() => import('../pages/auth/NewPassword')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

const Pdf = Loadable(lazy(() => import('../pages/dashboard/Pdf/PDF')));
const Cv = Loadable(lazy(() => import('../pages/dashboard/Cv/Cv')));


const Video = Loadable(lazy(() => import('../pages/dashboard/Videos/Video')));
const EditVideo = Loadable(lazy(() => import('../pages/dashboard/Videos/EditVideo')));
const AddVideo = Loadable(lazy(() => import('../pages/dashboard/Videos/AddVideo')));

const Numerical = Loadable(lazy(() => import('../pages/dashboard/Numerical/Numerical')));
const EditNumerical = Loadable(lazy(() => import('../pages/dashboard/Numerical/EditNumerical')));
const AddNumerical = Loadable(lazy(() => import('../pages/dashboard/Numerical/AddNumerical')));

const Verbal = Loadable(lazy(() => import('../pages/dashboard/Verbal/Verbal')));
const EditVerbal = Loadable(lazy(() => import('../pages/dashboard/Verbal/EditVerbal')));
const AddVerbal = Loadable(lazy(() => import('../pages/dashboard/Verbal/AddVerbal')));

const Logical = Loadable(lazy(() => import('../pages/dashboard/Logical/Logical')));
const EditLogical = Loadable(lazy(() => import('../pages/dashboard/Logical/EditLogical')));
const AddLogical = Loadable(lazy(() => import('../pages/dashboard/Logical/AddLogical')));

const Situational = Loadable(lazy(() => import('../pages/dashboard/Situational/Situational')));
const EditSituational = Loadable(lazy(() => import('../pages/dashboard/Situational/EditSituational')));
const AddSituational = Loadable(lazy(() => import('../pages/dashboard/Situational/AddSituational')));

const QuizResult = Loadable(lazy(() => import('../pages/dashboard/quiz/result')));

// const Home = Loadable(lazy(() => import('../pages/dashboard/Home/Home')));
const Subcription = Loadable(lazy(() => import('../pages/admin/Subscription/Subcription')));
const EditSubcription = Loadable(lazy(() => import('../pages/admin/Subscription/EditSubcription')));
const AddSubcription = Loadable(lazy(() => import('../pages/admin/Subscription/AddSubcription')));

const Addons = Loadable(lazy(() => import('../pages/admin/Addons/Addons')));
const EditAddons = Loadable(lazy(() => import('../pages/admin/Addons/EditAddons')));
const AddAddons = Loadable(lazy(() => import('../pages/admin/Addons/AddAddons')));

const Faq = Loadable(lazy(() => import('../pages/admin/faqs/Faq')));
const EditFaq = Loadable(lazy(() => import('../pages/admin/faqs/EditFaq')));
const AddFaq = Loadable(lazy(() => import('../pages/admin/faqs/AddFaq')));

const Pages = Loadable(lazy(() => import('../pages/admin/Pages/Pages')));
const EditPages = Loadable(lazy(() => import('../pages/admin/Pages/EditPages')));
const AddPages = Loadable(lazy(() => import('../pages/admin/Pages/AddPages')));

const Content = Loadable(lazy(() => import('../pages/admin/Pages/Content/Content')));
const EditContent = Loadable(lazy(() => import('../pages/admin/Pages/Content/EditContent')));
const AddContent = Loadable(lazy(() => import('../pages/admin/Pages/Content/AddContent')));

const AdminPdf = Loadable(lazy(() => import('../pages/admin/Pdf/PDF')));
const AdminEditPdf = Loadable(lazy(() => import('../pages/admin/Pdf/EditPDF')));
const AdminAddPdf = Loadable(lazy(() => import('../pages/admin/Pdf/AddPDF')));


const AdminCv = Loadable(lazy(() => import('../pages/admin/Cv/CV')));
const AdminEditCv = Loadable(lazy(() => import('../pages/admin/Cv/EditCV')));
const AdminAddCv = Loadable(lazy(() => import('../pages/admin/Cv/AddCV')));

const AdminVideo = Loadable(lazy(() => import('../pages/admin/Videos/Video')));
const AdminEditVideo = Loadable(lazy(() => import('../pages/admin/Videos/EditVideo')));
const AdminAddVideo = Loadable(lazy(() => import('../pages/admin/Videos/AddVideo')));

const AdminNumerical = Loadable(lazy(() => import('../pages/admin/Numerical/Numerical')));
const AdminEditNumerical = Loadable(lazy(() => import('../pages/admin/Numerical/EditNumerical')));
const AdminAddNumerical = Loadable(lazy(() => import('../pages/admin/Numerical/AddNumerical')));
const NumericalQuestion = Loadable(lazy(() => import('../pages/admin/Numerical/Question/NumericalQuesion')));
const EditNumericalQuestion = Loadable(lazy(() => import('../pages/admin/Numerical/Question/EditNumericalQuesion')));
const AddNumericalQuestion = Loadable(lazy(() => import('../pages/admin/Numerical/Question/AddNumericalQuesion')));

const AdminLogical = Loadable(lazy(() => import('../pages/admin/Logical/Logical')));
const AdminEditLogical = Loadable(lazy(() => import('../pages/admin/Logical/EditLogical')));
const AdminAddLogical = Loadable(lazy(() => import('../pages/admin/Logical/AddLogical')));
const LogicalQuestion = Loadable(lazy(() => import('../pages/admin/Logical/Question/LogicalQuesion')));
const AddLogicalQuestion = Loadable(lazy(() => import('../pages/admin/Logical/Question/AddLogicalQuesion')));
const EditLogicalQuestion = Loadable(lazy(() => import('../pages/admin/Logical/Question/EditLogicalQuesion')));

const AdminVerbal = Loadable(lazy(() => import('../pages/admin/Verbal/Verbal')));
const AdminEditVerbal = Loadable(lazy(() => import('../pages/admin/Verbal/EditVerbal')));
const AdminAddVerbal = Loadable(lazy(() => import('../pages/admin/Verbal/AddVerbal')));
const VerbalQuestion = Loadable(lazy(() => import('../pages/admin/Verbal/Question/VerbalQuesion')));
const AddVerbalQuestion = Loadable(lazy(() => import('../pages/admin/Verbal/Question/AddVerbalQuesion')));
const EditVerbalQuestion = Loadable(lazy(() => import('../pages/admin/Verbal/Question/EditVerbalQuesion')));

const AdminSituational = Loadable(lazy(() => import('../pages/admin/Situational/Situational')));
const AdminEditSituational = Loadable(lazy(() => import('../pages/admin/Situational/EditSituational')));
const AdminAddSituational = Loadable(lazy(() => import('../pages/admin/Situational/AddSituational')));
const SituationalQuestion = Loadable(lazy(() => import('../pages/admin/Situational/Question/SituationalQuesion')));
const AddSituationalQuestion = Loadable(lazy(() => import('../pages/admin/Situational/Question/AddSituationalQuesion')));
const EditSituationalQuestion = Loadable(lazy(() => import('../pages/admin/Situational/Question/EditSituationalQuesion')));

const AdminAccount = Loadable(lazy(() => import('../pages/admin/AdminAccount')));
// Admin

// GENERAL
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Resources = Loadable(lazy(() => import('../pages/Resources')));
const ServicesOtherServices = Loadable(lazy(() => import('../pages/ServicesOtherServices')));
const PackageDetail = Loadable(lazy(() => import('../pages/PackageDetail')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
// const TermsOfAgreement = Loadable(lazy(() => import('../pages/TermsOfAgreement')));

// extra
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));

const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// extra
