
// routes
import { useEffect, useState } from 'react';
import { PATH_PAGE } from '../../routes/paths';
// components
// components
import Iconify from '../../components/Iconify';
import axiosInstance from '../../utils/axios';

// ----------------------------------------------------------------------



const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: 'Contact Us',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: PATH_PAGE.contact,
  },
  {
    title: 'About Us',
    path: PATH_PAGE.about,
    icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
  },
  {
    title: 'Pricing',
    icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    path: PATH_PAGE.pricing,
  },
]


const DynamicMenuConfig = () => {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await axiosInstance.get('user/all/dropdown/view'); // Replace with your API URL
        setPages(response?.data?.data);
      } catch (error) {
        console.error('Error fetching pages:', error);
      }
    };

    fetchPages();
  }, []);

  const dynamicPages = {
    title: 'Employers Guide',
    path: '/pages',
    icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
    children: [
      {
        items: pages.map((page) => ({
          title: page.resource_name,
          path: `/pages/${page.id}`,
        })),
      },
    ],
  };

  return [...menuConfig, dynamicPages];
};

export default DynamicMenuConfig;
