/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_ADMIN_DASHBOARD,PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children,roleType }) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={roleType === 'admin' ? PATH_ADMIN_DASHBOARD.root : PATH_DASHBOARD.general.analytics} />;
  }

  return <>{children}</>;
}
