// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ADMIN = '/admin';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  forgotVerify: (token) => path(ROOTS_ADMIN, `/forgotVerify/${token}`),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/newPassword'),

  // admin
  adminLogin: path(ROOTS_ADMIN, '/login'),

};

export const PATH_PAGE = {
  about: '/about-us',
  TermsOfAgreement: '/terms-of-agreement',
  contact: '/contact-us',
  // extra
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  // extra
};

export const PATH_ADMIN_DASHBOARD = {
  root: ROOTS_ADMIN,
  dashboard: path(ROOTS_ADMIN, '/analytics'),
  account: path(ROOTS_ADMIN, '/account'),
  users: {
    root: path(ROOTS_ADMIN, '/users'),
    new: path(ROOTS_ADMIN, '/users/new'),
    list: path(ROOTS_ADMIN, '/users/list'),
    edit: (id) => path(ROOTS_ADMIN, `/users/${id}/edit`),
  },
  subscription: {
    root: path(ROOTS_ADMIN, '/subscription'),
    subscription: path(ROOTS_ADMIN, '/subscription/subscription'),
    addsubscription: path(ROOTS_ADMIN, '/subscription/addsubscription'),
    editsubscription: (id) => path(ROOTS_ADMIN, `/subscription/editsubscription/${id}`),
  },
  addons: {
    root: path(ROOTS_ADMIN, '/addons'),
    addons: path(ROOTS_ADMIN, '/addons/addons'),
    addaddons: path(ROOTS_ADMIN, '/addons/addaddons'),
    editaddons: (id) => path(ROOTS_ADMIN, `/addons/editaddons/${id}`),
  },
  faq: {
    root: path(ROOTS_ADMIN, '/faq'),
    faq: path(ROOTS_ADMIN, '/faq/faq'),
    addfaq: path(ROOTS_ADMIN, '/faq/addfaq'),
    editfaq: (id) => path(ROOTS_ADMIN, `/faq/editfaq/${id}`),
  },
  pages: {
    root: path(ROOTS_ADMIN, '/pages'),
    pages: path(ROOTS_ADMIN, '/pages/pages'),
    addpages: path(ROOTS_ADMIN, '/pages/addpages'),
    editpages: (id) => path(ROOTS_ADMIN, `/pages/editpages/${id}`),
    content: (id)=>path(ROOTS_ADMIN, `/pages/content/${id}`),
    addcontent: (id)=>path(ROOTS_ADMIN, `/pages/addcontent/${id}`),
    editcontent: (id) => path(ROOTS_ADMIN, `/pages/editcontent/${id}`),
  },
  pdf: {
    root: path(ROOTS_ADMIN, '/pdf'),
    pdf: path(ROOTS_ADMIN, '/pdf/pdf'),
    addpdf: path(ROOTS_ADMIN, '/pdf/addpdf'),
    editpdf: (id) => path(ROOTS_ADMIN, `/pdf/editpdf/${id}`),
  },
  cv: {
    root: path(ROOTS_ADMIN, '/cv'),
    cv: path(ROOTS_ADMIN, '/cv/cv'),
    addcv: path(ROOTS_ADMIN, '/cv/addcv'),
    editcv: (id) => path(ROOTS_ADMIN, `/cv/editcv/${id}`),
  },
  video: {
    root: path(ROOTS_ADMIN, '/video'),
    video: path(ROOTS_ADMIN, '/video/video'),
    addvideo: path(ROOTS_ADMIN, '/video/addvideo'),
    editvideo: (id) => path(ROOTS_ADMIN, `/video/editvideo/${id}`),
  },
  numerical: {
    root: path(ROOTS_ADMIN, '/numerical'),
    numerical: path(ROOTS_ADMIN, '/numerical/numerical'),
    addnumerical: path(ROOTS_ADMIN, '/numerical/addnumerical'),
    editnumerical: (id) => path(ROOTS_ADMIN, `/numerical/editnumerical/${id}`),
    question: (id) =>path(ROOTS_ADMIN, `/numerical/question/${id}`),
    addquestion: (id) =>path(ROOTS_ADMIN, `/numerical/addquestion/${id}`),
    editquestion: (id) => path(ROOTS_ADMIN, `/numerical/editquestion/${id}`),
  },
  logical: {
    root: path(ROOTS_ADMIN, '/logical'),
    logical: path(ROOTS_ADMIN, '/logical/logical'),
    addlogical: path(ROOTS_ADMIN, '/logical/addlogical'),
    editlogical: (id) => path(ROOTS_ADMIN, `/logical/editlogical/${id}`),
    question: (id) =>path(ROOTS_ADMIN, `/logical/question/${id}`),
    addquestion: (id) =>path(ROOTS_ADMIN, `/logical/addquestion/${id}`),
    editquestion: (id) => path(ROOTS_ADMIN, `/logical/editquestion/${id}`),
  },
  verbal: {
    root: path(ROOTS_ADMIN, '/verbal'),
    verbal: path(ROOTS_ADMIN, '/verbal/verbal'),
    addverbal: path(ROOTS_ADMIN, '/verbal/addverbal'),
    editverbal: (id) => path(ROOTS_ADMIN, `/verbal/editverbal/${id}`),
    question: (id) =>path(ROOTS_ADMIN, `/verbal/question/${id}`),
    addquestion: (id) =>path(ROOTS_ADMIN, `/verbal/addquestion/${id}`),
    editquestion: (id) => path(ROOTS_ADMIN, `/verbal/editquestion/${id}`),
  },
  situational: {
    root: path(ROOTS_ADMIN, '/situational'),
    situational: path(ROOTS_ADMIN, '/situational/situational'),
    addsituational: path(ROOTS_ADMIN, '/situational/addsituational'),
    editsituational: (id) => path(ROOTS_ADMIN, `/situational/editsituational/${id}`),
    question: (id) =>path(ROOTS_ADMIN, `/situational/question/${id}`),
    addquestion: (id) =>path(ROOTS_ADMIN, `/situational/addquestion/${id}`),
    editquestion: (id) => path(ROOTS_ADMIN, `/situational/editquestion/${id}`),
  },
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  calculateClosingCost: path(ROOTS_DASHBOARD, '/calculate-closing-cost'),
  general: {
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  quizResult: path(ROOTS_DASHBOARD, '/quiz/result'),
  pdf: {
    root: path(ROOTS_DASHBOARD, '/pdf'),
    pdf: path(ROOTS_DASHBOARD, '/pdf/pdf'),
    addpdf: path(ROOTS_DASHBOARD, '/pdf/addpdf'),
    editpdf: (id) => path(ROOTS_DASHBOARD, `/pdf/editpdf/${id}`),
  },
  cv: {
    root: path(ROOTS_DASHBOARD, '/cv'),
    cv: path(ROOTS_DASHBOARD, '/cv/cv'),
    // addpdf: path(ROOTS_DASHBOARD, '/pdf/addpdf'),
    // editpdf: (id) => path(ROOTS_DASHBOARD, `/pdf/editpdf/${id}`),
  },
  video: {
    root: path(ROOTS_DASHBOARD, '/video'),
    video: path(ROOTS_DASHBOARD, '/video/video'),
    addvideo: path(ROOTS_DASHBOARD, '/video/addvideo'),
    editvideo: (id) => path(ROOTS_DASHBOARD, `/video/editvideo/${id}`),
  },
  numerical: {
    root: path(ROOTS_DASHBOARD, '/numerical'),
    numerical: path(ROOTS_DASHBOARD, '/numerical/numerical'),
    addnumerical: path(ROOTS_DASHBOARD, '/numerical/addnumerical'),
    editnumerical: (id) => path(ROOTS_DASHBOARD, `/numerical/editnumerical/${id}`),
  },
  logical: {
    root: path(ROOTS_DASHBOARD, '/logical'),
    logical: path(ROOTS_DASHBOARD, '/logical/logical'),
    addlogical: path(ROOTS_DASHBOARD, '/logical/addlogical'),
    editlogical: (id) => path(ROOTS_DASHBOARD, `/logical/editlogical/${id}`),
  },
  verbal: {
    root: path(ROOTS_DASHBOARD, '/verbal'),
    verbal: path(ROOTS_DASHBOARD, '/verbal/verbal'),
    addverbal: path(ROOTS_DASHBOARD, '/verbal/addverbal'),
    editverbal: (id) => path(ROOTS_DASHBOARD, `/verbal/editverbal/${id}`),
  },
  situational: {
    root: path(ROOTS_DASHBOARD, '/situational'),
    situational: path(ROOTS_DASHBOARD, '/situational/situational'),
    addsituational: path(ROOTS_DASHBOARD, '/situational/addsituational'),
    editsituational: (id) => path(ROOTS_DASHBOARD, `/situational/editsituational/${id}`),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
