import { Icon } from '@iconify/react';
import { PATH_DASHBOARD } from '../../../routes/paths';

import SvgIconStyle from '../../../components/SvgIconStyle';
// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  team: getIcon('ic_team'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

// Retrieve addons from localStorage and parse it
const addons = JSON.parse(localStorage.getItem('currentuser'));

// Debugging: Check the structure of the addons object
console.log("Addons object:", addons);

// Function to safely parse JSON multiple times if needed
const safeParseJSON = (jsonString) => {
  try {
    let parsed = JSON.parse(jsonString);
    while (typeof parsed === 'string') {
      parsed = JSON.parse(parsed);
    }
    return parsed;
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return [];
  }
};

// Extract package_addons and ensure it's properly parsed
const getPackageAddons = (addons?.packages || []).flatMap((e) => {
  const packageAddons = e?.packages?.package_addons;
  console.log("Raw package_addons:", packageAddons); // Debugging: Check raw package_addons

  if (packageAddons) {
    const parsedAddons = safeParseJSON(packageAddons);
    console.log("Parsed package_addons:", parsedAddons); // Debugging: Check parsed package_addons
    return Array.isArray(parsedAddons) ? parsedAddons : [];
  }
  return [];
});

// Debugging: Check the results of getPackageAddons
console.log("getPackageAddons:", getPackageAddons);

// Extract addons titles
const getAddonsInPackage = (addons?.packages || []).map((e) => e?.addons?.title).filter(Boolean); // Filter out undefined values

// Debugging: Check the results of getAddonsInPackage
console.log("getAddonsInPackage:", getAddonsInPackage);

// Merge the arrays and remove duplicates
const mergedAddons = [...getPackageAddons, ...getAddonsInPackage];
const uniqueAddons = Array.from(new Set(mergedAddons));

console.log(addons, "addons")
console.log(getPackageAddons, "addons")
console.log(getAddonsInPackage, "addons")
console.log(uniqueAddons, "uniqueAddons");

const navConfig = [
  {
    items: [
      { title: 'Dashboard', path: '/dashboard/analytics', icon: <Icon icon="ri:dashboard-line" /> },
      {
        title: 'Resources',
        icon: ICONS.user,
        children: [
          { title: 'Numerical', path: PATH_DASHBOARD.numerical.numerical },
          { title: 'Logical', path: PATH_DASHBOARD.logical.logical },
          { title: 'Verbal', path: PATH_DASHBOARD.verbal.verbal },
          { title: 'Situational Judgement', path: PATH_DASHBOARD.situational.situational },
          { title: 'PDF', path: PATH_DASHBOARD.pdf.pdf },
          { title: 'CV', path: PATH_DASHBOARD.cv.cv },

          { title: 'Video', path: PATH_DASHBOARD.video.video },
        ].filter(item => uniqueAddons.includes(item.title)),
      },
    ],
  },
];

export default navConfig;
