// routes
// import { Icon } from '@mui/material';
import { PATH_ADMIN_DASHBOARD } from '../../../routes/paths';
// components
// import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  team: getIcon('ic_team'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_ADMIN_DASHBOARD.dashboard, icon: ICONS.dashboard },
      { title: 'Subscription', path: PATH_ADMIN_DASHBOARD.subscription.subscription, icon: ICONS.banking },
      { title: 'Addons', path: PATH_ADMIN_DASHBOARD.addons.addons, icon: ICONS.cart },
      { title: 'Faqs', path: PATH_ADMIN_DASHBOARD.faq.faq, icon: ICONS.chat },
      { title: 'Pages', path: PATH_ADMIN_DASHBOARD.pages.pages, icon: ICONS.cart },
      { title: 'User', path: PATH_ADMIN_DASHBOARD.users.list, icon: ICONS.user },
      {
        title: 'Resources',
        // path: PATH_ADMIN_DASHBOARD.users.root,
        icon: ICONS.user,
        children: [
          { title: 'Numerical', path: PATH_ADMIN_DASHBOARD.numerical.numerical },
          { title: 'Logical', path: PATH_ADMIN_DASHBOARD.logical.logical },
          { title: 'Verbal', path: PATH_ADMIN_DASHBOARD.verbal.verbal },
          { title: 'Situational', path: PATH_ADMIN_DASHBOARD.situational.situational },
          { title: 'Interview Question and Answers Guide PDF', path: PATH_ADMIN_DASHBOARD.pdf.pdf },
          { title: 'CV and Cover Letter Template PDF'
            , path: PATH_ADMIN_DASHBOARD.cv.cv
           },

          // { title: 'Video', path: PATH_ADMIN_DASHBOARD.video.video },
        ],
      },
    ],
  },
];

export default navConfig;
