/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { ColorRing } from 'react-loader-spinner'

function Loader () {
    return (
        <>
            <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#fff', '#fff', '#fff', '#FFF', '#fff']}
            />
        </>
    )
}


export default Loader


